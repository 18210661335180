import axios from "axios";
const token = localStorage.getItem('token');
const client_ip = localStorage.getItem('client_ip');
console.log(token)

const axiosMain = axios.create({
  // baseURL: "http://144.126.255.193/",
  // baseURL: "https://partner.medyseva.com/",
  // baseURL: "https://api.medyseva.com/",
  // baseURL: "http://127.0.0.1:8085",
  // baseUrl:"http://localhost:8085/",
  //baseURL: "https://apimedyseva.tejpage.com/",
  baseURL: "https://lmh.api.medyseva.com/",
  // process.env.NODE_ENV === 'development'
  //   ? process.env.REACT_APP_END_POINT_URL_DEV
  //   : process.env.REACT_APP_END_POINT_URL_PROD,
  headers: {
    "Authorization": 'Bearer '+token,
    "Content-Type": "application/json",
    "ClientIp":client_ip
  },
});
export default axiosMain;

// wKUd%8P*%469%c1#