import React, { useState, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import useAuth from "../../../hooks/useAuth";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import {
  IoIosApps,
  IoIosBrowsers,
  IoIosClock,
  IoMdAlbums,
  IoIosWallet,    
  IoIosAlbums,
  IoIosUnlock,
  IoIosCube,
  IoIosToday,
} from "react-icons/io";
import { Button } from "react-bootstrap";
import { logout } from "../../../reducer/authReducers";
import { useDispatch } from "react-redux";
import axios from "axios";
import axiosMain from "../../../http/axios/axios_main";
import helpers from "../../../Helper/CryptoHelper";

const Header = ({ setToogleNav }) => {

  let userlastlogin=localStorage.getItem("loginAddress");
  const dispatch = useDispatch();
  const { user, last_login } = useAuth();
  const [vlepatients, setvlepatients] = useState(0);
  const [vleappoitments, setvleappoitments] = useState(0);
  const [vlebalance, setvlebalance] = useState(0);

  const handleLogout = async () => {
    dispatch(logout());
  };

  const getData = async () => {
    let inputdata = {
      user_id: user,
    };

    let res = await axiosMain.post("/api/vle-dashboard", helpers.encrypt(JSON.stringify(inputdata))).catch(function (error) {
      if (error.response) {
        if(error.response.status === 401){
          alert('exiting');
          dispatch(logout());
          localStorage.removeItem('token');
          window.location.pathname = "/";
        }
      }
    });
    res.data = helpers.decrypt(JSON.stringify(res.data));
    console.log(res.data.original.data, "fine");
    if (res.data.original.status == "1") {
      setvlebalance(res.data.original.data.balance);
      setvleappoitments(res.data.original.data.appointments);
      setvlepatients(res.data.original.data.patients);
    }
  };

  useEffect(() => {
    getData();
  }, []);

let lastlogintime=JSON.parse(userlastlogin)

  return (
    <div>
      <nav
        className="navbar navbar-main navbar-expand-lg px-0  shadow-none border-radius-xl"
        id="navbarBlur"
        navbar-scroll="true"
      >
        <div className="container-fluid py-1 px-3">
          <div
            className="row collapse navbar-collapse mt-sm-0 mt-2 me-md-0 justify-content-sm-between justify-content-end dashboard-presale"
            id="navbar"
          >
            <div className="col-md-12  d-flex  align-items-center">
              <div className="nav-item d-xl-none ps-3 align-items-center">
                <a
                  href=""
                  onClick={(e) => {
                    setToogleNav((prevState) => !prevState);
                    e.preventDefault();
                  }}
                  className="nav-link text-body p-0"
                  id="iconNavbarSidenav"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </a>
              </div>
              <ul className="d-flex m-0 hidden-print">
                <Link to="/dashboard">
                  <li className="nav-item dropdown pe-2 d-flex align-items-center">

                    {user.country=="india" ? (
                      <img                                                           
                      className="img_logo img-fluid"
                      src="assets\img\logo\logo.png"
                    />

                    ):(
                      <img                                                           
                      className="img_logo img-fluid"
                      src="assets\img\logo\logo.png"
                    />
                    )}
                    
                  </li>
                </Link>
              </ul>
              {/* <div className="user-profile">
                  <button type="button" className="profile-inner" style={{cursor:'auto'}}>
                    
                    <h2 style={{color:'#053479'}}>{user.name.slice(0, 2)}</h2>
                 
                  </button>
                
                </div> */}
                
                


              <div className="dropdown user-profile hidden-print">
              <DropdownButton id="dropdown-basic-button" title={user.name}>
                <Dropdown.Item href="#/action-1"><b>Last login Date:</b> &nbsp; <br></br>{lastlogintime?.date}</Dropdown.Item>
              </DropdownButton>
                {/* <button
                  className="btn btn-drop-user dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="assets\img\profile.svg"
                    className="user-image img-fluid"
                    alt="user"
                  />
                  <span>
                    <b> {user.name}</b>
                  </span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{width:'100%'}}>
                  <li className="user-image-1">
                  <a className="dropdown-item" href="#">
                  <div className="select_user">
                      <i className="fas fa-calendar-alt user_logo"></i>
                      <div>
                      <h6 style={{fontWeight:'bold'}}>Last login Date</h6>
                      
                       <p>{lastlogintime.date}</p>
                       
                       </div>&nbsp;&nbsp;

                       </div>
                    </a>
                  </li>
                  <li className="user-image-1">
                  <a className="dropdown-item" href="#">
                  <div className="select_user">
                      <i className="fas fa-money-bill-wave user_logo"></i>
                      <div>
                      <h6 style={{fontWeight:'bold'}}>Wallet Amount</h6>
                      
                       <p>{user.wallet_amount}</p>
                       
                       </div>&nbsp;&nbsp;

                       </div>
                    </a>
                  </li>
                  {/* <li>
                    <a className="dropdown-item" href="#">
                    <i className="fas fa-wallet"></i> Wallet{" "}
                    </a>
                  </li> */}
                  {/* <li>
                    <a className="dropdown-item" href="#">
                    <i className="fa fa-sign-out"></i> Logout
                    </a>
                  </li> */}
                
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
